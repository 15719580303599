import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ServiceDetailsContent from "../components/ServiceDetails/ServiceDetailsContent"
import RelatedServices from "../components/ServiceDetails/RelatedServices"
import ProducDesignImage from "../assets/images/services/ProductDesign.png"

const Product = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Product & Design"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Product & Design"
      />
      <ServiceDetailsContent
        aboutService="Product designing is exactly what end-user see. Our creative graphics designers will make a suitable and fresh design ideas for your product.​​"
        technologies={["Adobe Photoshop", "Adobe illustrator", "Adobe XD"]}
        detailImage={ProducDesignImage}
      />
      {/* <RelatedServices /> */}
      <Footer />
    </Layout>
  )
}

export default Product
